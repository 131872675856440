export const LS_THANKYOU_PAGE_TYPE = 'thankyou-page-type'

// export const ENV = process.env.ENV || "dev"
export const ENV = 'prod'

// Mailing list form submission URL. This will push emails to a Google Sheet
export const MAILING_LIST_FORM_URL = {
  "dev": "https://script.google.com/macros/s/AKfycbzHe2fMTNW89VaePPpOoJUphekxRTXf0VJyJkgVst0tBpM24iD9NhqoapypXAB6IzQh5w/exec",
  "prod": "https://script.google.com/macros/s/AKfycbxlPhhej4QCjfen5EFC682j-UYfIj0GxU9KnUeIU45GMTAkbmkXt4k3Yk_PqRhJs4dYtw/exec"
}

export const RECAPTCHA_SITE_KEY = {
  "dev": "6LdhuZUaAAAAAPUxii44mRX4KcbSxZozQ_TD0tyc",
  "prod": "6LdJwdkaAAAAAF5RJPFCkMtYCFkefcaBtDfga-wT"
}