import React from "react";

import "../../css/main.css";
import "../../css/fonts.css";
import "../../css/reset.css";

import Seo from "../../components/SeoHead";

const Media = ({ data }) => {
  // const youtubeVideos = getYoutubeVideos(data);
  return (
    <>
      <Seo />
    </>
  );
};

export default Media;
