import { withPrefix } from "gatsby-link";
import React from "react";
import Helmet from "react-helmet";
import { RECAPTCHA_SITE_KEY, ENV } from '../constants';

import { useCookies } from "utils/useCookies";

import data from "../data";

// const GOOGLE_TARGET_ID = "UA-122874263-3"; // Google Analytics
// const API_HOST = "api.skyweaver.net";

export default function Seo(props) {
  const { img = data.SEO.img, desc = data.SEO.desc, title = data.SEO.title } = props;

  const { rejectsCookies } = useCookies()

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={desc} />
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="application-name" content={title} />
      <meta name="robots" content="index,follow" />
      <meta name="googlebot" content="index,follow" />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={img} />
      <meta property="og:description" content={desc} />
      <meta property="og:site_name" content={title} />
      <meta property="og:locale" content="en_US" />
      {/* <meta property="og:url" content={url} /> */}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@0xHorizonGames" />
      {/* <meta name="twitter:url" content={url} /> */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={desc} />
      <meta name="twitter:image" content={img} />

      <link rel="apple-touch-icon-precomposed" sizes="57x57" href="/images/favicon/apple-touch-icon-57x57.png" />
      <link rel="apple-touch-icon-precomposed" sizes="114x114" href="/images/favicon/apple-touch-icon-114x114.png" />
      <link rel="apple-touch-icon-precomposed" sizes="72x72" href="/images/favicon/apple-touch-icon-72x72.png" />
      <link rel="apple-touch-icon-precomposed" sizes="144x144" href="/images/favicon/apple-touch-icon-144x144.png" />
      <link rel="apple-touch-icon-precomposed" sizes="60x60" href="/images/favicon/apple-touch-icon-60x60.png" />
      <link rel="apple-touch-icon-precomposed" sizes="120x120" href="/images/favicon/apple-touch-icon-120x120.png" />
      <link rel="apple-touch-icon-precomposed" sizes="76x76" href="/images/favicon/apple-touch-icon-76x76.png" />
      <link rel="apple-touch-icon-precomposed" sizes="152x152" href="/images/favicon/apple-touch-icon-152x152.png" />
      <link rel="icon" type="image/png" href="/images/favicon/favicon-196x196.png" sizes="196x196" />
      <link rel="icon" type="image/png" href="/images/favicon/favicon-96x96.png" sizes="96x96" />
      <link rel="icon" type="image/png" href="/images/favicon/favicon-32x32.png" sizes="32x32" />
      <link rel="icon" type="image/png" href="/images/favicon/favicon-16x16.png" sizes="16x16" />
      <link rel="icon" type="image/png" href="/images/favicon/favicon-128.png" sizes="128x128" />
      <meta name="msapplication-TileColor" content="#FFFFFF" />
      <meta name="msapplication-TileImage" content="/images/favicon/mstile-144x144.png" />
      <meta name="msapplication-square70x70logo" content="/images/favicon/mstile-70x70.png" />
      <meta name="msapplication-square150x150logo" content="/images/favicon/mstile-150x150.png" />
      <meta name="msapplication-wide310x150logo" content="/images/favicon/mstile-310x150.png" />
      <meta name="msapplication-square310x310logo" content="/images/favicon/mstile-310x310.png" />

      <meta name="twitter:dnt" content="on" />


      <link rel="preload" href={withPrefix("/fonts/Barlow-Medium.woff2")} as="font" type="font/woff2" crossorigin />
      <link rel="preload" href={withPrefix("/fonts/Barlow-Regular.woff2")} as="font" type="font/woff2" crossorigin />
      <link rel="preload" href={withPrefix("/fonts/Barlow-SemiBold.woff2")} as="font" type="font/woff2" crossorigin />
      <link rel="preload" href={withPrefix("/fonts/BarlowCondensed-Medium.woff2")} as="font" type="font/woff2" crossorigin />
      <link rel="preload" href={withPrefix("/fonts/BarlowCondensed-Regular.woff2")} as="font" type="font/woff2" crossorigin />


      {/* <script async src={`https://${API_HOST}/gtm/p-gtm/gtag/js?id=${GOOGLE_TARGET_ID}`}></script>
        <script>
          {`
            window.ga = window.ga || function () {
                (ga.q = ga.q || []).push(arguments);
            };
            ga.l = +new Date;
            ga('create', '${GOOGLE_TARGET_ID}', 'auto');
            ga('send', 'pageview');

            function gtag() {
                dataLayer.push(arguments);
            }

            var request = new XMLHttpRequest();
            request.open('GET', 'https://${API_HOST}/gtm/p-ga/analytics.js', true);

            console.log('loading seo');
            request.onload = function () {
                if (request.status >= 200 && request.status < 400) {
                    var proxiedScript = request.responseText
                        .replace(/www\\.googletagmanager\\.com/gi, '${API_HOST}/gtm/p-gtm')
                        .replace(/www\\.google-analytics\\.com/gi, '${API_HOST}/gtm/p-ga')
                        .replace(/https:\\/\\/ampcid\\.google\\.com/gi, '${API_HOST}/gtm/p-ac');
                    var script = document.createElement('script');
                    script.innerHTML = proxiedScript;
                    document.head.appendChild(script);
                    window.dataLayer = window.dataLayer || [];
                    gtag('js', new Date());
                    gtag('config', '${GOOGLE_TARGET_ID}');
                }
            };
            request.send();
          `}
        </script> */}

      {!rejectsCookies && <script async src={`https://www.googletagmanager.com/gtag/js?id=UA-122874263-3`}></script>}
      {!rejectsCookies && <script>
        {`
          console.log("loaded gtag script");
          window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments); }
          gtag('js', new Date());
        
          gtag('config', 'UA-122874263-3');
          `}
      </script>}

      {!rejectsCookies && <script>
        {/* Fetch the Segment JS SDK by proxying through skyweaver.net domain to circumvent ad blockers */}
        {`
        ! function () {
          console.log('attempting to load seg.min.js');
          var analytics = window.analytics = window.analytics || [];
          if (!analytics.initialize) {
            if (analytics.invoked) {
              console.log('analytics.invoked TRUE')
              window.console && console.error && console.error("Segment snippet included twice.");
            } else {
              console.log('analytics.invoked FALSE');
              analytics.invoked = !0;
              analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset",
                "group", "track", "ready", "alias", "debug", "page", "once", "off", "on"
              ];
              analytics.factory = function (t) {
                return function () {
                  var e = Array.prototype.slice.call(arguments);
                  e.unshift(t);
                  analytics.push(e);
                  return analytics
               }
              };
              for (var t = 0; t < analytics.methods.length; t++) {
                var e = analytics.methods[t];
                analytics[e] = analytics.factory(e)
              }
              analytics.load = function (t, e) {
                var n = document.createElement("script");
                var apiHost = 'https://api.skyweaver.net';
                n.type = "text/javascript";
                n.async = !0;
                n.src = apiHost + '/seg/v1/' + t + '/seg.min.js';
                var a = document.getElementsByTagName("script")[0];
                a.parentNode.insertBefore(n, a);
                analytics._loadOptions = e
              };
              analytics.SNIPPET_VERSION = "4.1.0";
              console.log('analytics.load() = ');
              console.log(analytics.load)
              analytics.load('i5jD9T1cea1XDHSurGbAJr4SHYHxyDY4', {
                integrations: {
                  'All': true,
                  'Segment.io': {apiHost: 'api.skyweaver.net/v1'}
                }
              })
            }
          } else {
            console.log('analytics.initialized');
          }
        }();
        `}
      </script>}
      {!rejectsCookies && (<script src={`https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY[ENV]}`}></script>)}

    </Helmet>
  );
}
